import React from "react"
import { navigate } from "gatsby-link"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"

import workStyle from "assets/jss/material-kit-react/views/sponsorshipPageSections/workStyle.jsx"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      devType: "",
      interest: "",
      hearAbout: "",
      location: "",
      message: "",
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              action="/success"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="bot" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <GridContainer sx={{ fontFamily: "Mandali" }}>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "name",
                      required: true,
                      varient: "outlined",
                      onChange: e => this.setState({ name: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      sx: { fontFamily: "Mandali" },
                      name: "email",
                      required: true,
                      varient: "outlined",
                      value: this.state.email,
                      onChange: e => this.setState({ email: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel sx={{ fontFamily: "Mandali" }} id="developType">
                    Type of Development
                  </InputLabel>

                  <Select
                    sx={{ fontFamily: "Mandali" }}
                    labelId="developType"
                    id="devType"
                    name="devType"
                    value={this.state.devType}
                    onChange={e => this.setState({ devType: e.target.value })}
                    required={true}
                    fullWidth
                  >
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Wind Farm"}
                    >
                      Wind Farm
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Solar Park"}
                    >
                      Solar Park
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Overhead Line"}
                    >
                      Overhead Line
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Energy Storage"}
                    >
                      Energy Storage
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Mandali" }} value={"Other"}>
                      Other
                    </MenuItem>
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel sx={{ fontFamily: "Mandali" }} id="intType">
                    Your Interest
                  </InputLabel>
                  <Select
                    sx={{ fontFamily: "Mandali" }}
                    labelId="intType"
                    id="interest"
                    name="interest"
                    value={this.state.interest}
                    onChange={e => this.setState({ interest: e.target.value })}
                    required
                    fullWidth
                  >
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Developer"}
                    >
                      Developer
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Local Community or Resident"}
                    >
                      Local Community or Resident
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Planning Authority"}
                    >
                      Planning Authority
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Mandali" }} value={"Other"}>
                      Other
                    </MenuItem>
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel sx={{ fontFamily: "Mandali" }} id="hearType">
                    How did you hear about us?
                  </InputLabel>
                  <Select
                    sx={{ fontFamily: "Mandali" }}
                    labelId="hearType"
                    id="hearAbout"
                    name="hearAbout"
                    value={this.state.hearAbout}
                    onChange={e => this.setState({ hearAbout: e.target.value })}
                    required
                    fullWidth
                  >
                    <MenuItem sx={{ fontFamily: "Mandali" }} value={"Referral"}>
                      Referral
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Our work on other projects"}
                    >
                      Our work on other projects
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Mandali" }}
                      value={"Web search"}
                    >
                      Web search
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Mandali" }} value={"Other"}>
                      Other
                    </MenuItem>
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your location (postcode)"
                    id="postcode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    sx={{ fontFamily: "Mandali" }}
                    inputProps={{
                      sx: { fontFamily: "Mandali" },
                      name: "location",
                      onChange: e =>
                        this.setState({ location: e.target.value }),
                      required: true,
                    }}
                  />
                </GridItem>
                <CustomInput
                  labelText="How can we help?"
                  id="enquiry"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea,
                  }}
                  inputProps={{
                    sx: { fontFamily: "Mandali" },
                    name: "message",
                    multiline: true,
                    rows: 15,
                    onChange: e => this.setState({ message: e.target.value }),
                    required: true,
                  }}
                />

     
              </GridContainer>
              <Button
                  type="submit"
                  color="primary"
                  sx={{ fontFamily: "Mandali", padding: '2rem' }}
                >
                  Send Message
                </Button>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(workStyle)(ContactForm)
